<template>
  <div>
    <transaction-list :type="'withdrawal'"></transaction-list>
  </div>
</template>

<script>
import TransactionList from './TransactionList.vue';

export default {
  components: { TransactionList },
  name: "WithdrawList",
  data: () => ({
  }),
  methods: {
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped></style>
